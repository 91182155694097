import ContactForm from "./ContactForm";
import classes from "./HeroBanner.module.css";

const HeroBanner = () => {
  return (
    <section id={classes["hero-banner"]}>
      <div className={classes["banner-container"]}>
        <div className={classes["banner-text"]}>
          <h2 className={classes["banner-h2"]}>Serving the Manchester area</h2>
          <h1 className={classes["banner-h1"]}>
            Professional Carpet & Upholstery Cleaning Across Greater Manchester
          </h1>
        </div>
        <div className={classes["banner-form"]}>
          <ContactForm />
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
