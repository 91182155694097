import classes from "./Services.module.css";
import footer from "../Layout/Footer.module.css";

/* import apartment from "../../assets/apartment-optimized.jpg";
import largeOffice from "../../assets/large-office-optimized.jpg";
import tech from "../../assets/tech-optimized.jpg"; */

import comparison from "../../assets/comparison-optimized.jpg";
import office from "../../assets/office-optimized.jpg";
import bigSofa from "../../assets/big-sofa-optimized.jpg";
import mattress from "../../assets/mattress-optimized.jpg";
import hardFloor from "../../assets/hard-floor-optimized.jpg";

import Button from "../UI/Button";

const Services = () => {
  return (
    <section id={classes.services}>
      {/* <div className={classes["grid-images"]}>
        <div
          className={classes["grid-img-wrapper"]}
          style={{ backgroundImage: `url('${apartment}')` }}
        >
          <div className={classes["service-text-wrapper-top"]}>
            <p>Rapid Dry Time</p>
          </div>
        </div>

        <div
          className={classes["grid-img-wrapper"]}
          style={{ backgroundImage: `url('${largeOffice}')` }}
        >
          <div className={classes["service-text-wrapper-top"]}>
            <p>Professional Results</p>
          </div>
        </div>

        <div className={classes["grid-img-wrapper"]} style={{ backgroundImage: `url('${tech}')` }}>
          <div className={classes["service-text-wrapper-top"]}>
            <p>Advanced Technology Equipment</p>
          </div>
        </div>
      </div> */}

      <h2 id={classes["services-h2"]}>Services</h2>

      <div className={classes["flex-images"]}>
        <div
          className={classes["flex-img-wrapper"]}
          style={{ backgroundImage: `url('${comparison}')` }}
        >
          <div className={classes["service-text-wrapper-bottom"]}>
            <p>Carpet Cleaning</p>
            <a href={"#" + footer["contact-us"]}>
              <Button id={classes["more-button"]} type="button" text="Find out more" />
            </a>
          </div>
        </div>

        <div
          className={classes["flex-img-wrapper"]}
          style={{ backgroundImage: `url('${office}')` }}
        >
          <div className={classes["service-text-wrapper-bottom"]}>
            <p>Stain Removal</p>
            <a href={"#" + footer["contact-us"]}>
              <Button id={classes["more-button"]} type="button" text="Find out more" />
            </a>
          </div>
        </div>

        <div
          className={classes["flex-img-wrapper"]}
          style={{ backgroundImage: `url('${hardFloor}')` }}
        >
          <div className={classes["service-text-wrapper-bottom"]}>
            <p>Hard Floor Cleaning</p>
            <a href={"#" + footer["contact-us"]}>
              <Button id={classes["more-button"]} type="button" text="Find out more" />
            </a>
          </div>
        </div>

        <div
          className={classes["flex-img-wrapper"]}
          style={{ backgroundImage: `url('${bigSofa}')` }}
        >
          <div className={classes["service-text-wrapper-bottom"]}>
            <p>Upholstery Cleaning</p>
            <a href={"#" + footer["contact-us"]}>
              <Button id={classes["more-button"]} type="button" text="Find out more" />
            </a>
          </div>
        </div>

        <div
          className={classes["flex-img-wrapper"]}
          style={{ backgroundImage: `url('${mattress}')` }}
        >
          <div className={classes["service-text-wrapper-bottom"]}>
            <p>Mattress Cleaning</p>
            <a href={"#" + footer["contact-us"]}>
              <Button id={classes["more-button"]} type="button" text="Find out more" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
