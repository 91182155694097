import "./App.css";
import Layout from "./components/Layout/Layout";
import AboutUs from "./components/Home/AboutUs";
import Services from "./components/Home/Services";
import HeroBanner from "./components/Home/HeroBanner";
import ContactForm from "./components/Home/ContactForm";

function App() {
  return (
    <Layout>
      <HeroBanner />
      <AboutUs />
      <Services />
      <div className="home">
        <ContactForm />
      </div>
    </Layout>
  );
}

export default App;
