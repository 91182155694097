import classes from "./Footer.module.css";

import { FaFacebookF, FaInstagram, FaPhoneAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <footer id={classes["contact-us"]}>
      <div className={classes["social-icons"]}>
        <a href="tel:+447946072576" target="_blank" rel="noreferrer">
          <FaPhoneAlt />
        </a>
        <a href="https://www.facebook.com/profile.php?id=100090507967331" target="_blank" rel="noreferrer">
          <FaFacebookF />
        </a>
        <a href="https://www.instagram.com/revivenclean_carpetcleaning" target="_blank" rel="noreferrer">
          <FaInstagram />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
