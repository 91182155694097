/* 
  Header and Navigation bar
*/

import { useState } from "react";

import { Transition } from "react-transition-group";

import { FaBars } from "react-icons/fa";
import { RiCloseLine } from "react-icons/ri";

import classes from "./MainNavigation.module.css";
import services from "../Home/Services.module.css";
import aboutUs from "../Home/AboutUs.module.css";
import footer from "./Footer.module.css";

import logo from "../../assets/revive-logo.png";

// Sidebar default styling
const defaultStyles = {
  transition: `all 750ms ease-in-out`,
  transform: "translateX(-100%)",
};

// Sidebar transition styles
const transitionStyles = {
  entering: { transform: "translateX(-100%)" },
  entered: { transform: "translateX(0)" },
  exiting: { transform: "translateX(-100%)" },
  exited: { transform: "translateX(-100%)" },
};

// Backdrop default styling
const backdropStyles = {
  transition: `all 750ms ease-in-out`,
  transform: "translateY(-100%)",
};

// Backdrop transition styles
const backdropTransitionStyles = {
  entering: { transform: "translateY(-100%)" },
  entered: { transform: "translateY(0)" },
  exiting: { transform: "translateY(-100%)" },
  exited: { transform: "translateY(-100%)" },
};

// Main Nav & Sidebar
const MainNavigation = () => {
  const [showMenu, setShowMenu] = useState(false);

  const menuToggleHandler = () => {
    setShowMenu((prevState) => !prevState);
  };

  // Main navigation header
  return (
    // ensure sidebar disappears if screen sizes expands
    // use dev tools to check this, .sidebar display: none; ?
    // MAKE SIDEBAR SECTION A COMPONENT AND USE
    // React Transition Group
    <>
      <Transition
        in={showMenu}
        appear={showMenu}
        timeout={750}
        /*  mountOnEnter={true}
         unmountOnExit={true} */
      >
        {(state) => (
          <>
            {showMenu && (
              <section
                className={classes.backdrop}
                onClick={menuToggleHandler}
                style={{
                  ...backdropStyles,
                  ...backdropTransitionStyles[state],
                }}></section>
            )}
            <section
              className={classes.sidebar}
              style={{
                ...defaultStyles,
                ...transitionStyles[state],
              }}>
              <h2>Menu</h2>
              <ul className={classes["sidebar-links"]}>
                <li className={classes["sidebar-link"]}>
                  <a href={"#" + aboutUs["about-us"]} onClick={menuToggleHandler}>
                    About Us
                  </a>
                </li>
                <li className={classes["sidebar-link"]}>
                  <a href={"#" + services["services-h2"]} onClick={menuToggleHandler}>
                    Services
                  </a>
                </li>
                <li className={classes["sidebar-link"]}>
                  <a href={"#" + footer["contact-us"]} onClick={menuToggleHandler}>
                    Contact
                  </a>
                </li>
              </ul>
            </section>
          </>
        )}
      </Transition>
      {/* showMenu && (
        <section
          className={classes.backdrop}
          onClick={menuToggleHandler}
        ></section>
      ) */}
      <header className={classes.header}>
        <a href="#" className={classes["logo-wrapper"]}>
          <img src={logo} className={classes.logo} alt="logo" />
        </a>

        {!showMenu && (
          // think about making the divs buttons?
          <div className={classes.hamburger} onClick={menuToggleHandler}>
            <FaBars />
          </div>
        )}

        {showMenu && (
          <div className={classes.close} onClick={menuToggleHandler}>
            <RiCloseLine />
          </div>
        )}

        <div className={classes.desktop}>
          <a href="tel:07946072576">
            <p className={classes["menu-phone-number"]}>Call: 0794 607 2576</p>
          </a>

          <nav className={classes.links}>
            <ul>
              <li className={classes["header-link"]}>
                <a href={"#" + aboutUs["about-us"]}>About Us</a>
              </li>
              <li className={classes["header-link"]}>
                <a href={"#" + services["services-h2"]}>Services</a>
              </li>
              <li className={classes["header-link"]}>
                <a href={"#" + footer["contact-us"]}>Contact</a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};

export default MainNavigation;
