import classes from "./Button.module.css";

const Button = ({ id, type, text }) => {
  return (
    <button className={classes.button} id={id} type={type}>
      {text}
    </button>
  );
};
export default Button;
