import classes from "./AboutUs.module.css";

import apartment from "../../assets/apartment-optimized.jpg";
import largeOffice from "../../assets/large-office-optimized.jpg";
import tech from "../../assets/tech-optimized.jpg";

const AboutUs = () => {
  return (
    <section id={classes["about-us"]}>
      <h2 className={classes["about-h2"]}>Welcome to Revive Carpet Cleaning</h2>
      <div className={classes["about-us-text"]}>
        <h3 className={classes[""]}>Providing Carpet and Upholstery Cleaning You Can Trust</h3>
        <p>
          The premier cleaning service for carpets and upholstery in Greater Manchester and its
          surrounding areas. Our team of seasoned professionals is dedicated to delivering
          exceptional results that exceed your expectations. With our cutting-edge technology and
          eco-friendly solutions, we can remove even the most stubborn stains and eliminate
          unpleasant odours in no time.
        </p>

        <p>
          Our top priority is customer satisfaction. We know that clean carpets and upholstery can
          transform the look and feel of your home, which is why we strive to provide you with a
          service that is tailored to your specific needs. From fabric to leather to velvet, our
          team of experts can handle it all. With over eight years of experience in the industry, we
          have honed our skills to provide you with a level of service that is unmatched.
        </p>

        <div className={classes["grid-images"]}>
          <div
            className={classes["grid-img-wrapper"]}
            style={{ backgroundImage: `url('${apartment}')` }}
          >
            <div className={classes["service-text-wrapper-top"]}>
              <p>Rapid Dry Time</p>
            </div>
          </div>

          <div
            className={classes["grid-img-wrapper"]}
            style={{ backgroundImage: `url('${largeOffice}')` }}
          >
            <div className={classes["service-text-wrapper-top"]}>
              <p>Professional Results</p>
            </div>
          </div>

          <div
            className={classes["grid-img-wrapper"]}
            style={{ backgroundImage: `url('${tech}')` }}
          >
            <div className={classes["service-text-wrapper-top"]}>
              <p>Advanced Technology Equipment</p>
            </div>
          </div>
        </div>

        <h3>Impressive Results Delivered by Industry Experts</h3>
        <p>
          When you choose Revive, you're choosing a team of industry experts who are dedicated to
          delivering impressive results every time. We use the latest technology to ensure that your
          carpets and upholstery are cleaned thoroughly, leaving no dirt or grime behind. Our
          fast-drying technology means that your carpets and upholstery are ready to use in just 30
          minutes, so you can get back to your busy life without any delay.
        </p>
        <p>
          We understand that cleaning carpets and upholstery can be a daunting task, which is why we
          make it easy for you. Our personalised approach means that we work with you to understand
          your specific needs and tailor our service accordingly. Whether you're dealing with pet
          odours, allergens, or stubborn stains, we have the expertise to get the job done right.
        </p>
        <p>
          Revive is a company you can trust for all your carpet and upholstery cleaning needs.
          Contact us today to schedule your appointment and experience the Revive difference for
          yourself.
        </p>
      </div>
    </section>
  );
};

export default AboutUs;
